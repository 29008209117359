import cookies from "js-cookie";
import React from "react";
import { Sentry } from "sentry";

import { useAppContext } from "#components/AppContext";
import topNotification from "#components/topNotification";
import { Steps, VOUCHER_COOKIE_NAME } from "#containers/Booking/consts";
import { countsToPassengersArray } from "#containers/Booking/helpers";
import { toISODateString } from "#utils/";

import { useBooking } from "..";

export default function useBoookingFetchers() {
  const { api } = useAppContext();
  const { setShow, setRebookJourneyId, setUpgradeJourneyId } = useBooking();

  const getRoutes = React.useCallback(async () => {
    try {
      const routesResponse = await api.getRoutes();

      return routesResponse.data;
    } catch (error) {
      console.error(error, error.respose);

      return undefined;
    }
  }, [api]);

  const getCampaigns = React.useCallback(
    async (rebookJourneyId, upgradeJourneyId) => {
      try {
        const campaignsResponse = await api.getCampaigns({
          rebook_journey_id: rebookJourneyId,
          upgrade_journey_id: upgradeJourneyId,
        });

        return campaignsResponse.data;
      } catch (error) {
        console.error(error, error.respose);

        return undefined;
      }
    },
    [api]
  );

  const getVoucher = React.useCallback(
    async (voucherCode) => {
      try {
        const voucherResponse = await api.getVoucher({ code: voucherCode });

        return voucherResponse.data;
      } catch (error) {
        const status = error?.response?.status;

        if (
          // 412 = Max use reached
          status === 404 ||
          status === 412
        ) {
          cookies.remove(VOUCHER_COOKIE_NAME);
          return undefined;
        }
        throw error;
      }
    },
    [api]
  );

  const getEventPage = React.useCallback(
    async (eventSlug) => {
      try {
        const eventResponse = await api.getEventPage({ slug: eventSlug });

        return eventResponse.data;
      } catch (error) {
        const status = error?.response?.status;

        if (status === 404) {
          return undefined;
        }

        throw error;
      }
    },
    [api]
  );

  const getDepartures = React.useCallback(
    async ({
      from,
      to,
      date,
      event,
      extras,
      counts,
      voucher,
      forOutbound = true,
      rebook_journey_id,
      upgrade_journey_id,
    }) => {
      try {
        const { data: departures } = await api.getJourneys({
          origin_id: from.id,
          destination_id: to.id,
          date: toISODateString(date),
          for_outbound: forOutbound,
          event,
          passengers: countsToPassengersArray(counts),
          has_stroller: extras.stroller,
          has_pet: extras.pet,
          wheelchairs: extras.wheelchairs,
          walkers: extras.walkers,
          voucher_code: voucher?.code,
          rebook_journey_id,
          upgrade_journey_id,
        });

        return departures;
      } catch (error) {
        console.error(error, error.response);
        const { status, data } = error?.response || {};

        const invalidJourneyIdData = data?.rebook_journey_id?.find(
          ({ code }) => code === "invalid"
        );

        const isInvalid =
          Array.isArray(data) && data.some(({ code }) => code === "invalid");

        if (status === 400 && invalidJourneyIdData) {
          setShow(Steps.SEARCH);
          setRebookJourneyId(undefined);
          setUpgradeJourneyId(undefined);
          topNotification(invalidJourneyIdData.detail);
        } else if (Array.isArray(data)) {
          topNotification(data.map(({ detail }) => detail).join(", "));
        }

        if (isInvalid) {
          setShow(Steps.SEARCH);
        }

        Sentry.captureException(error);

        return undefined;
      }
    },
    [api, setShow, setRebookJourneyId, setUpgradeJourneyId]
  );

  return {
    getRoutes,
    getVoucher,
    getDepartures,
    getEventPage,
    getCampaigns,
  };
}
