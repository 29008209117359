import { createRoot } from "react-dom/client";

const ID = "renderBarebones";

// This function will render a modal without the need of
// returning the actual modal in a Component. It can be useful
// for taking in user input and resolving a promise based on that.
// It can just be used to render a modal though. An issue is that
// it cannot use components that uses context.

export function renderBarebones({ children }) {
  const element = document.createElement("div");
  element.id = ID;
  const root = createRoot(element);

  const close = () => {
    root.unmount();
    element?.parentNode?.removeChild?.(element);
  };

  removePrevious();
  root.render(children);
  document.documentElement.appendChild(element);

  return { close };
}

function removePrevious() {
  /* eslint-disable-next-line */
  for (const previous of document.querySelectorAll(`#${ID}`)) {
    const root = createRoot(previous);
    root.unmount();
    previous?.parentNode?.removeChild?.(previous);
  }
}

// When hot reloading, there can be left-over elements from previous runs.
if (typeof document !== "undefined") {
  removePrevious();
}
